export const createDeepProxy = <T extends Function>(handle: T, path: Array<string> = []): Function => {
	const memoizedValue: { [key: string]: Function } = {}

	const apply = (target: T, thisArg: any, args: Array<any>) => {
		return target(path)(...args)
	}

	return new Proxy(handle, {
		get: (target, prop: string) => {
			if (prop === 'apply') {
				return (thisArg: any, args: Array<any>) => {
					apply(target, thisArg, args)
				}
			}
			if (memoizedValue[prop]) {
				return memoizedValue[prop]
			}
			memoizedValue[prop] = createDeepProxy(handle, [...path, prop])
			return memoizedValue[prop]
		},
		apply,
	})
}
